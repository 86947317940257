import { Component, OnInit } from '@angular/core';
import { DadosMonitoramento } from '../models/dados-monitoramento';
import { MonitoramentoService } from '../services/monitoramento.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {

  dados: DadosMonitoramento[];

  constructor(private _monitoraService: MonitoramentoService) {
    this.dados = [];
   }

  ngOnInit(): void {
    this._monitoraService.getDadosMonitoramento().subscribe(resp => {
      this.dados = resp;
    })
  }

}
