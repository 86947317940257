import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DadosMonitoramento } from '../models/dados-monitoramento';

@Injectable({
  providedIn: 'root'
})
export class MonitoramentoService {

  constructor(private _httpCliente: HttpClient) { }


  getDadosMonitoramento(): Observable<DadosMonitoramento[]> {
    return this._httpCliente.get<DadosMonitoramento[]>('https://quiet-crag-15841.herokuapp.com/monitoramento');
  } 

}
