<table class="table table-dark table-striped">
    <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Data</th>
          <th scope="col">Dia</th>
          <th scope="col">Hora</th>
          <th scope="col">Nível do tanque</th>
          <th scope="col">Pressão Absoluta</th>
          <th scope="col">Pressão Relativa</th>
          <th scope="col">Temperatura</th>
          <th scope="col">Umidade Relativa</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dados; index as i;">
          <th scope="row">{{i}}</th>
          <td>{{item.data}}</td>
          <td>{{item.dia}}</td>
          <td>{{item.hora}}</td>
          <td>{{item.nivelTanque}}</td>
          <td>{{item.pressaoAbsoluta}}</td>
          <td>{{item.pressaoRelativa}}</td>
          <td>{{item.temperatura}}</td>
          <td>{{item.umidadeRelativa}}</td>
        </tr>
      </tbody>
  </table>
